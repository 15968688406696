<template>
  <form @submit.prevent="submitBody">
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="$t('label.nueva')+$t('label.eirTransaction')"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol sm="11" lg="12">
          <CSelect
            :label="$t('label.eirType')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            :placeholder="$t('label.select')"
            addLabelClasses="required text-right"
            @change="changeTpEirId($event)"
            :value="TpEirId"
            :options="computedTpEirList"
            v-model.trim="$v.TpEirId.$model"
            :is-valid="hasError($v.TpEirId)"
            :invalid-feedback="errorMessage($v.TpEirId)"
          />
        </CCol>
      </CRow>
       <CRow>
            <CCol sm="12" lg="12">
              <CInput
                :label="$t('label.name')+' '+('(ES)')"
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                v-uppercase
                addLabelClasses="required text-right"
                :placeholder="$t('label.eirTypeTransactionName')+' '+('(ES)')"
                v-model.trim="$v.TpTransacEirNameEs.$model"
                :is-valid="hasError($v.TpTransacEirNameEs)"
                :invalid-feedback="errorMessage($v.TpTransacEirNameEs)"
              />
            </CCol>
            <CCol sm="12" lg="12">
              <CInput
                :label="$t('label.name')+' '+('(EN)')"
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                v-uppercase
                addLabelClasses="required text-right"
                :placeholder="$t('label.eirTypeTransactionName')+' '+('(EN)')"
                v-model.trim="$v.TpTransacEirNameEn.$model"
                :is-valid="hasError($v.TpTransacEirNameEn)"
                :invalid-feedback="errorMessage($v.TpTransacEirNameEn)"
              />
            </CCol>
            <CCol sm="12" lg="12">
              <CInput
                :label="$t('label.acronym')"
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                v-uppercase
                addLabelClasses="required text-right"
                :placeholder="$t('label.acronym')"
                v-model.trim="$v.Acronym.$model"
                :is-valid="hasError($v.Acronym)"
                :invalid-feedback="errorMessage($v.Acronym)"
              />
            </CCol>
            <CCol sm="12" lg="12">
              <CInput
                type="color"
                :label="$t('label.color')"
                addLabelClasses="required text-right"
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                :invalid-feedback="errorMessage($v.Color)"
                :is-valid="hasError($v.Color)"
                v-model.trim="$v.Color.$model"
              />
            </CCol>
        </CRow>
            <template #footer>
                <CButton
                square
                color="add"
                class="d-flex align-items-center"
                type="submit"
                :disabled="isSubmit"
                >
                <div v-if="!isSubmit">
                    <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
                </div>
                <div v-if="isSubmit">
                    <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="ml-1">{{$t('button.accept')}}</span>
                </div>
                </CButton>
                <CButton
                square
                color="wipe"
                class="d-flex align-items-center"
                @click="toggle(false)"
                >
                <CIcon name="x" /><span class="ml-1"
                    >{{$t('button.cancel')}}</span
                >
                </CButton>
            </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import { required, maxLength, helpers } from 'vuelidate/lib/validators';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    TpEirId: '',
    TpTransacEirNameEs: '',
    TpTransacEirNameEn: '',
    Acronym: '',
    Color: '',
    tpEirSelect: [],
    apiStateLoading: false, 
  };
}

function changeTpEirId(event) {
  this.TpEirId = event.target.value;
}

function getTpEirList() {
  this.apiStateLoading = true;
  this.$http
    .get('TpEir-list?Filter=ACTIVO')
    .then((res) => (this.tpEirSelect = res.data.data));
  this.apiStateLoading = false;  
}

function submitBody() {
  try {
    this.$v.$touch();
    this.apiStateLoading = true;
    if (this.$v.$error) {
      this.apiStateLoading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const TpEirTransacJson = {
      TpEirId: this.TpEirId,
      TpTransacEirNameEs: this.TpTransacEirNameEs,
      TpTransacEirNameEn: this.TpTransacEirNameEn,
      Acronym: this.Acronym,
      Color: this.Color
    };

    this.$http
      .post('TpEirTransac-insert', TpEirTransacJson, { root: 'TpEirTransacJson' })
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          const data = response.data.data[0];
          const TpEirTransacJson = data;
          this.resetForm();
          this.$emit('set-modal-eir-transc-list', TpEirTransacJson);
          this.apiStateLoading = false;
          this.toggle(false);
          this.notifySuccess({text: messageSuccess})
        } 
      }).catch((e) => {
        this.apiStateLoading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.apiStateLoading = false;
    this.notifyError({text: e});
  }
}

function resetForm() {
  this.TpEirId = '';
  this.TpTransacEirNameEs = '';
  this.TpTransacEirNameEn = '';
  this.Acronym = '',
  this.Color = '',
  this.$v.$reset();
}
function computedTpEirList(){
  let _lang = this.$i18n.locale;
  if(this.tpEirSelect.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.tpEirSelect.map(function(e){
      chart.push({
        value: e.TpEirId, 
        label: _lang=='en' ? e.TpEirNameEn : e.TpEirNameEs,
      })    
    })
    return chart;
  }
}



export default {
  name: 'modal-eir-transc-create',
  props: { modal: Boolean },
  data,
  mixins: [
    ModalMixin,
  ],
  validations: {
    TpTransacEirNameEs: { required, maxLength: maxLength(100) },
    TpTransacEirNameEn: { required, maxLength: maxLength(100) },
    Acronym: { required, maxLength: maxLength(10)},
    Color: { required},
    TpEirId:{required}
  },
  directives: UpperCase,
  methods: {
    changeTpEirId,
    submitBody,
    resetForm,
    getTpEirList,
  },
  computed: {
    computedTpEirList,
  },
  mounted: getTpEirList,
  watch: {
    modal: function(val){
      this.modalActive = val;
      if(val){
         this.getTpEirList();
      }
    }
  },
 
};
</script>